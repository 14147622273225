<script>
import {vueAppMixin} from "@/mixins/vueAppMixin";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import {permissionMixin} from "@/mixins/permissionMixins";
import attachmentMixin from "@/mixins/attachmentMixin";
import AppLoading from "@/components/elements/AppLoading.vue";
import NewRsInspectionReport from "@/components/layouts/NewRsInspectionReport";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/services/AlertService";

export default {
  name: "RsIndex",
  mixins: [vueAppMixin, PaginationMixin, permissionMixin, attachmentMixin],
  data() {
    return {
      filters: {
        page: 1,
        count: 30,
        fleet_id: null,
        status_id: null,
        office_id: null,
        flag_id: null,
        company_id: null,
      },
      loading: false,
    }
  },
  components: {
    'new-rs-inspection-report': NewRsInspectionReport,
    'loading': AppLoading,
  },
  methods: {
    ...mapActions([
      'addRequestKeys',
      'generateRequestKeys',
      'getRsReports',
      'deleteRs',
    ]),

    setPage(page = null) {
      page = page ? page : this.filters.page;
      localStorage.setItem('accidentIncidentPage', page);
      this.pagination.currentPage = page;
      this.filters.page = page
      this.getRsReports(this.filters);
    },

    showRsInspection(inspectionId) {
      this.$router.push(
        {
          name: 'RsMain',
          params: {
            id: inspectionId
          }
        }
      );
    },

    async verifyDelete(rs) {
      if (await AlertService.confirmDeleteAlert('Delete Rightship Inspection', 'Do you want to delete this inspection?', 'Yes Proceed')) {
        const params = {type: 1, id: rs.id}
        const response = await this.deleteRs(params);

        if (response) {
          AlertService.successAlert('Inspection successfully deleted', 'DELETE RIGHTSHIP');
          this.setPage(1);
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      // 'isLoading', 'vesselKeys', 'fleetKeys',
      'isLoading', 'reports', 'vesselKeys', 'fleetKeys', 'portKeys',
      'mngmntOfficeKeys', 'countryKeys', 'reportStatusKeys', 'mngmtCompanyKeys'
    ]),
  },
  async created() {
    this.addRequestKeys([
      'getVesselKeys',
      'getFleetListKeys',
      'getReportStatusKeys',
      'getMngmtOfficeKeys',
      'getCountryListKeys',
      'getMngmtCompanyKeys',
      'getTimezoneKeys',
      'getEventOneKeys',
      'getEventImpactKeys',
      'getPortKeys',
    ]);
    const params = {};

    this.loading = true;

    await this.generateRequestKeys(params);
    await this.getRsReports(this.filters);

    this.loading = false;
  },
  watch: {
    async 'filters.count'() {
      this.loading = true;
      await this.getRsReports(this.filters);
      this.loading = false;
    }
  }
}
</script>

<template>
  <div class="text-center">
    <div class="row no-gutters justify-content-between mb-1">
      <div class="col-sm-auto flex-grow-0">
        <h3 class="mb-0 text-left">Rightship Inspection List</h3>
      </div>
      <div class="col-sm-auto">
        <div class="row">
          <div class="col-sm-auto float-right pr-0">
            <button class="e-btn e-btn-green force-white-all" data-toggle="modal" data-target="#NewRsInspection">
              <font-awesome-icon icon="plus-square" style="width: 25px"/>
              <small class="font-weight-bold px-2">
                NEW RIGHTSHIP INSPECTION
              </small>
            </button>
          </div>
          <!--          <div class="col-sm-auto float-right pr-0">-->
          <!--            <button class="e-btn e-btn-blue force-white-all pr-3" data-toggle="modal" data-target="#ImportRsReportModal">-->
          <!--              <font-awesome-icon icon="plus-square" style="width: 25px"/>-->
          <!--              <small class="font-weight-bold">-->
          <!--                IMPORT-->
          <!--              </small>-->
          <!--            </button>-->
          <!--          </div>-->
          <!--          <div class="col-sm-auto float-right">-->
          <!--            <template>-->
          <!--              <div class="input-group">-->
          <!--                <button class="e-btn e-btn-blue-gray force-white-all">-->
          <!--                  <font-awesome-icon icon="file-excel" style="width: 20px"/>-->
          <!--                  <small class="font-weight-bold">-->
          <!--                    RIGHTSHIP INSPECTION TEMPLATE-->
          <!--                  </small>-->
          <!--                </button>-->
          <!--              </div>-->
          <!--            </template>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
    <div class="row no-gutters justify-content-between mb-1">
      <div class="col-sm-auto pb-2 mr-2">
        <nav aria-label="...">
          <ul class="pagination text-smallest mb-0 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setPage(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item"
                :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setPage(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setPage(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-sm-auto" v-if="1==0">
        <div class="row mb-3 no-gutters justify-content-between align-items-center">
          <div class="col-md-auto">
            <label class="col-form-label pr-2 font-weight-bold">Filter By: </label>
          </div>
          <div class="col-md-auto px-1">
            <select name="" id="" class="form-inline form-control form-control-sm form-control-xs"
                    v-model="filters.fleet_id">
              <option :value="null">-- ALL FLEET --</option>
              <option :value="fleet.id" v-for="fleet in fleetKeys">{{ fleet.name ? fleet.name.toUpperCase() : '' }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-sm-auto pb-2">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <label class="input-group-text input-group-sm bg-white small border-0" for="rowCount"># of Row(s):</label>
          </div>
          <select class="form-control form-control-sm rounded-left" v-model="filters.count" id="rowCount">
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="10000">All</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row no-gutters" style="height: 75vh; overflow-y: auto">
      <div class="col-12">
        <table class="small e-tbl">
          <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
          <tr>
            <th class="align-middle" style="width: 1em" scope="col">#</th>
            <th class="align-middle" scope="col">VESSEL</th>
            <th class="align-middle" scope="col">MANAGER</th>
            <th class="align-middle" style="min-width: 25em" scope="col">REPORT TITLE</th>
            <th class="align-middle" style="width: 5em" scope="col">STATUS</th>
            <th class="align-middle" style="width: 5em" scope="col">INSPECTION REPORT</th>
            <th class="align-middle" style="width: 11em" scope="col">PORT</th>
            <th class="align-middle" style="width: 11em" scope="col">COUNTRY</th>
            <th class="align-middle" style="min-width: 7em" scope="col">DATE OF INSPECTION</th>
            <th class="align-middle" style="width: 4em" scope="col">NO. NON <br/> FINDINGS</th>
            <th class="align-middle" style="width: 12em" scope="col">RESPONSIBLE</th>
            <th class="align-middle" style="width: 12em" scope="col">PERSON IN CHARGE</th>
            <th class="align-middle" style="width: 60px" scope="col">ACTION</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="loading">
            <td colspan="100%">
              <loading></loading>
            </td>
          </tr>
          <tr v-else-if="!reports.length && !loading">
            <td colspan="100%" class="text-center py-3">
              No records found.
            </td>
          </tr>
          <template v-else>
            <tr class="text-uppercase" v-for="(report, index) in reports" :key="report.id">
              <td>{{ index + pagination.from }}</td>
              <td>{{ report.vessel }}</td>
              <td>{{ report.manager }}</td>
              <td>{{ report.title }}</td>
              <td class="align-middle text-white" :class="['bg-'+report.report_status_color]">
                {{ report.report_status }}
              </td>
              <td>
                <template v-if="report.rsAttachment">
                  <font-awesome-icon icon="file-pdf" v-if="report.rsAttachment.has_file" @click="viewRsDownloadFile(report.rsAttachment,1,'view',report.id)" size="3x"
                                     class="force-danger-all" style="cursor: pointer">
                  </font-awesome-icon>
                  <font-awesome-icon icon="file-pdf" v-else  size="3x"></font-awesome-icon>
                </template>
              </td>
              <td>{{ report.port }}</td>
              <td>{{ report.country }}</td>
              <td>{{ report.inspection_commencement_date }}</td>
              <td class="align-middle text-center"><p class="py-0 my-0" :class="{'h5 text-danger': report.non_conformity_count > 0}">
                {{ report.non_conformity_count }}</p></td>
              <td>{{ report.responsible_person }}</td>
              <td>{{ report.person_in_charge }}</td>
              <td class="align-middle text-center">
                <div class="dropdown dropright my-1" v-if="isElite() || (report.created_by_id == user.id && report.report_status_id == 1)">
                  <button id="actionDropdown" aria-expanded="false" aria-haspopup="true"
                          class="btn btn-sm btn-secondary force-white-all" data-toggle="dropdown">
                    <font-awesome-icon icon="cogs"/>
                  </button>
                  <div aria-labelledby="actionDropdown" class="dropdown-menu py-0">
                    <button class="dropdown-item text-secondary font-weight-bolder"
                            @click="showRsInspection(report.id)"
                            v-if="isElite() || isAllowed('rs.index.view')">
                      <font-awesome-icon icon="eye"/>
                      View
                    </button>
                    <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                            @click="verifyDelete(report)"
                            v-if="isElite() || isAllowed('rs.index.delete') || (report.created_by_id == user.id && report.report_status_id == 1)">
                      <font-awesome-icon icon="trash" class="force-danger-all"/>
                      Delete
                    </button>
                  </div>
                </div>
                <template v-else>
                  <button class="e-btn e-btn-blue e-btn-sm e-text-white my-1"
                          @click="showRsInspection(report.id)"
                          v-if="isElite() || isAllowed('rs.index.view')">
                    <font-awesome-icon icon="eye"/>
                    View
                  </button>
                </template>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>

    <new-rs-inspection-report/>

  </div>
</template>

<style scoped>

</style>
