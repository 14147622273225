<script>
import {mapActions, mapGetters} from 'vuex'
import jQuery from 'jquery'
import DateMixin from "@/mixins/DateMixin"
import {AlertService} from '../../services/AlertService'

// eslint-disable-next-line no-undef
let $ = jQuery

export default {
  name: 'NewRsInspectionReport',
  mixins: [DateMixin],
  data() {
    return {
      ports: [],
      report: {},
    }
  },
  methods: {
    // ALREADY REQUESTED FROM PARENT COMPONENT
    // ...mapActions(['addRequestKeys', 'generateRequestKeys']),
    ...mapActions(['createRsReport']),
    resetData(target_key) {
      if (this.hasOwnProperty(target_key)) {
        this[target_key] = this.getDataTemplate(target_key);
      }
    },
    getDataTemplate(target_key) {
      const data_templates = {
        report: {
          title: null,
          inspection_commencement_date: null,
          vessel_id: this.user.vessel_id,
          country_id: null,
          port_id: null,
        },
      };

      return data_templates.hasOwnProperty(target_key) ? data_templates[target_key] : null;
    },
    getPortsByCountry(id) {
      this.ports = id ? this.portKeys.filter(port => port.country_id === id) : [];
      this.report.port_id = null;
    },
    async addRsInspection() {
      if (await AlertService.confirmCreateAlert()) {
        const response = await this.createRsReport(this.report);

        if (response.status === 200) {
          const rs = response.data.rs;

          this.$parent.setPage(); // reload listing...
          this.resetData('report'); // reset modal data
          $("#NewRsInspection").modal('toggle'); // close modal

          await successAlert.fire({
            title: `ADD RIGHTSHIP INSPECTION SUCCESS`,
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEnterKey: false,
            allowEscapeKey: false,
            timer: 1500,
            scrollbarPadding: false
          }).then(async result => {
            if (result.isConfirmed) {
              return await this.$router.push({name: 'RsMain', params: {id: rs.main.id}});
            }
          });

          // return setTimeout(() => this.$router.push({name: 'RsMain', params: {id: rs.main.id}}), 3000); // redirect to created inspection after 3 seconds
        }
      }
    },
    async cancelAdd() {
      const response = await AlertService.cancelAlert();

      if (response) {
        $('#NewRsInspection').modal('hide');
        this.report = this.getDataTemplate('report');
      }
    }
  },
  async created() {
    this.report = this.getDataTemplate('report');
    // ALREADY REQUESTED FROM PARENT COMPONENT
    // this.addRequestKeys(['getVesselKeys', 'getTimezoneKeys', 'getEventOneKeys', 'getEventImpactKeys']);
    // await this.generateRequestKeys();
  },
  async mounted() {
    this.report.vessel_id = this.user.vessel_id;
  },
  computed: {
    ...mapGetters(['vesselKeys', 'countryKeys', 'portKeys', 'user']),
  },
  watch: {
    'report.country_id'(id) {
      this.$nextTick(() => this.getPortsByCountry(id));
    }
  }
}
</script>

<template>
  <!-- Modal -->
  <div class="modal fade" data-backdrop="static" id="NewRsInspection" role="dialog" aria-labelledby="NewRsInspectionTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <form class="modal-content" @submit.prevent="addRsInspection">
        <div class="modal-header">
          <h5 class="modal-title" id="NewRsInspectionTitle">ADD RIGHTSHIP INSPECTION REPORT</h5>
          <button type="button" class="close" @click.prevent="cancelAdd">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row no-gutters mb-1" v-show="!user.vessel_id">
            <label for="VesselList" class="font-weight-bold col-sm-3 col-form-label">Vessel</label>
            <div class="col-sm-9">
              <select class="form-control form-control-sm text-uppercase" id="VesselList" v-model="report.vessel_id" required
                      :disabled="user.vessel_id">
                <option :value="null">—— Select Vessel ——</option>
                <option v-for="vessel in vesselKeys" :key="vessel.id" :value="vessel.id">
                  {{ vessel.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row no-gutters mb-1">
            <label for="ReportTitle" class="font-weight-bold col-sm-3 col-form-label">Report Title</label>
            <div class="col-sm-9">
              <textarea class="form-control form-control-sm" id="ReportTitle" rows="3" v-model="report.title" required></textarea>
            </div>
          </div>
          <div class="form-group row no-gutters mb-1">
            <label for="InspectionDate" class="font-weight-bold col-sm-3 col-form-label">Commencement Date</label>
            <div class="col-sm-9 mt-3">
              <input type="datetime-local" :min="minimumDate + 'T00:00'" :max="currentDate + 'T23:59'" class="form-control form-control-sm"
                     id="InspectionCommencementDate"
                     v-model="report.inspection_commencement_date" required>
            </div>
          </div>
          <div class="form-group row no-gutters mb-1">
            <label for="CountryList" class="font-weight-bold col-sm-3 col-form-label">Country</label>
            <div class="col-sm-9">
              <select class="form-control form-control-sm text-uppercase" id="CountryList" v-model="report.country_id">
                <option :value="null">—— Select Country ——</option>
                <option v-for="country in countryKeys" :key="country.id" :value="country.id">
                  {{ country.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row no-gutters mb-1">
            <label for="PortList" class="font-weight-bold col-sm-3 col-form-label">Port</label>
            <div class="col-sm-9">
              <select class="form-control form-control-sm text-uppercase" id="PortList" v-model="report.port_id" :disabled="!ports.length">
                <option :value="null">—— Select Port ——</option>
                <option v-for="port in ports" :key="port.id" :value="port.id">
                  {{ port.name }} {{ port.locode_wrap }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" @click.prevent="cancelAdd">Close</button>
          <button type="submit" class="btn btn-sm btn-primary">Add Report</button>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>

</style>
